<template>
    <router-view />
</template>

<script>

    import App from "@/modules/contents/app"
    import News from "@/modules/admin/news"
    
    export default {

        data() {
            return {}
        },
        beforeCreate() {
            App.getSettings();
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {
            
        }

    }

</script>